<template>
  <div class="icon">
    icon
  </div>
</template>

<script>
export default {
  name: 'icon'
}
</script>

<style lang="scss" scoped>
</style>